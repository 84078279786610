import React, { Fragment, useState, useRef, useEffect } from "react";
import styles from "./HamburgerMenu.module.css";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function HamburgerMenu({ isSticky }: { isSticky: boolean }) {
  const { pathname } = useLocation();
  const { t } = useTranslation("translation", { keyPrefix: "Index" });

  const [openMenu, setMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      if (openMenu) {
        setMenuOpen(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [openMenu]);

  return (
    <Fragment>
      <div
        ref={menuRef}
        className={`${styles.hamburger_menu} ${
          openMenu ? ` ${styles.open} ` : ` `
        } ${isSticky ? styles.sticky : ""} ${
          pathname === "/" ? ` ${styles.transparent} ` : ""
        }`}
        onClick={() => setMenuOpen(!openMenu)}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div
        className={`${styles.mobile_menu_wrapper} ${
          openMenu ? ` ${styles.open} ` : ` `
        } ${isSticky ? styles.sticky : ""} ${
          pathname === "/" ? ` ${styles.transparent} ` : ""
        }`}
      >
        <div className={styles.menu}>
          <ul>
            <li onClick={() => setMenuOpen(!openMenu)}>
              <Link to="/" className="cursor-hover">
                {t("navbar.home")}
              </Link>
            </li>
            <li onClick={() => setMenuOpen(!openMenu)}>
              <Link to="/about" className="cursor-hover">
              {t("navbar.aboutUs")}
              </Link>
            </li>
            <li onClick={() => setMenuOpen(!openMenu)}>
              <Link to="/works" className="cursor-hover">
              {t("navbar.portfolio")}
              </Link>
            </li>
            <li onClick={() => setMenuOpen(!openMenu)}>
              <Link to="/recruit" className="cursor-hover">
              {t("navbar.recruit")}
              </Link>
            </li>
            <li
              className={styles.contact}
              onClick={() => setMenuOpen(!openMenu)}
            >
              <Link to="/contact" className="cursor-hover">
              {t("navbar.contactUs")}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </Fragment>
  );
}
